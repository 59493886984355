import Typography from "@mui/material/Typography";
import { faLinkedin, faGithub } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./Home.css";
import NavigationTabs from "../../components/Navigationtabs";
import routes from "../../config/routes";
import EmailIcon from "@mui/icons-material/Email";
import PhoneIcon from "@mui/icons-material/Phone";
import TextAnimation from "../../components/motion";

const Home = () => {
  return (
    <div className="home-container">
      <div className="content-section">
        <div className="right-section">
          <NavigationTabs routes={routes} />{" "}
          <TextAnimation inputText="Hi, I'm Marwa, and I'm a Flutter developer" />
          <Typography variant="body1">
            I'm 23 years old, and I'm based in Beirut, Lebanon. I strive to
            create elegant and efficient solutions that make an impact and solve
            a problem.
          </Typography>
          <div className="spacer"></div>
          <TextAnimation inputText="Contact" />
          <div className="social-links">
            <div className="social-link">
              <a
                href="https://www.linkedin.com/in/marwa-karaki/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FontAwesomeIcon icon={faLinkedin} />
                <span>linkedin.com/in/marwa-karaki/</span>
              </a>
            </div>
            <div className="social-link">
              <a
                href="https://gitlab.com/marwakaraki21"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FontAwesomeIcon icon={faGithub} />
                <span>gitlab.com/marwakaraki21</span>
              </a>
            </div>
            <div className="social-link">
              <a href="mailto:mmk100@mail.aub.edu">
                <EmailIcon /> {/* Use Material Icons */}
                <span>mmk100@mail.aub.edu</span>
              </a>
            </div>
            <div className="social-link">
              <a href="tel:+96170012097">
                <PhoneIcon /> {/* Use Material Icons */}
                <span>+96170012097</span>
              </a>
            </div>
          </div>
          <TextAnimation inputText="Roof" />
          <div className="social-link">
            <a href="https://apps.apple.com/app/roof-in-beirut/id6444747767">
              <span>Click to find it on the Appstore</span>
            </a>
          </div>
          <div className="social-link">
            <a href="https://play.google.com/store/apps/details?id=com.roof.inbeirut&pli=1">
              <span>Click to find it on the Playstore</span>
            </a>
          </div>
          <div className="spacer"></div>
          <TextAnimation inputText="My toolBox" />
          <div className="icon-row">
            <img
              src="https://raw.githubusercontent.com/devicons/devicon/master/icons/android/android-original-wordmark.svg"
              alt="android"
              width="40"
              height="40"
            />
            <img
              src="https://raw.githubusercontent.com/devicons/devicon/master/icons/amazonwebservices/amazonwebservices-original-wordmark.svg"
              alt="aws"
              width="40"
              height="40"
            />
            <img
              src="https://www.vectorlogo.zone/logos/microsoft_azure/microsoft_azure-icon.svg"
              alt="azure"
              width="40"
              height="40"
            />
            <img
              src="https://raw.githubusercontent.com/devicons/devicon/master/icons/css3/css3-original-wordmark.svg"
              alt="css3"
              width="40"
              height="40"
            />
            <img
              src="https://www.vectorlogo.zone/logos/dartlang/dartlang-icon.svg"
              alt="dart"
              width="40"
              height="40"
            />
            <img
              src="https://cdn.worldvectorlogo.com/logos/django.svg"
              alt="django"
              width="40"
              height="40"
            />
            <img
              src="https://raw.githubusercontent.com/devicons/devicon/master/icons/docker/docker-original-wordmark.svg"
              alt="docker"
              width="40"
              height="40"
            />
            <img
              src="https://raw.githubusercontent.com/devicons/devicon/master/icons/dot-net/dot-net-original-wordmark.svg"
              alt="dotnet"
              width="40"
              height="40"
            />
            <img
              src="https://www.vectorlogo.zone/logos/figma/figma-icon.svg"
              alt="figma"
              width="40"
              height="40"
            />
            <img
              src="https://www.vectorlogo.zone/logos/firebase/firebase-icon.svg"
              alt="firebase"
              width="40"
              height="40"
            />
            <img
              src="https://www.vectorlogo.zone/logos/pocoo_flask/pocoo_flask-icon.svg"
              alt="flask"
              width="40"
              height="40"
            />
            <img
              src="https://www.vectorlogo.zone/logos/flutterio/flutterio-icon.svg"
              alt="flutter"
              width="40"
              height="40"
            />
            <img
              src="https://www.vectorlogo.zone/logos/google_cloud/google_cloud-icon.svg"
              alt="gcp"
              width="40"
              height="40"
            />
            <img
              src="https://www.vectorlogo.zone/logos/git-scm/git-scm-icon.svg"
              alt="git"
              width="40"
              height="40"
            />
            <img
              src="https://www.vectorlogo.zone/logos/apache_hive/apache_hive-icon.svg"
              alt="hive"
              width="40"
              height="40"
            />
            <img
              src="https://raw.githubusercontent.com/devicons/devicon/master/icons/html5/html5-original-wordmark.svg"
              alt="html5"
              width="40"
              height="40"
            />
            <img
              src="https://raw.githubusercontent.com/devicons/devicon/master/icons/javascript/javascript-original.svg"
              alt="javascript"
              width="40"
              height="40"
            />
            <img
              src="https://www.vectorlogo.zone/logos/kotlinlang/kotlinlang-icon.svg"
              alt="kotlin"
              width="40"
              height="40"
            />
            <img
              src="https://raw.githubusercontent.com/devicons/devicon/master/icons/linux/linux-original.svg"
              alt="linux"
              width="40"
              height="40"
            />
            <img
              src="https://raw.githubusercontent.com/devicons/devicon/master/icons/mysql/mysql-original-wordmark.svg"
              alt="mysql"
              width="40"
              height="40"
            />
            <img
              src="https://raw.githubusercontent.com/devicons/devicon/master/icons/nodejs/nodejs-original-wordmark.svg"
              alt="nodejs"
              width="40"
              height="40"
            />
            <img
              src="https://raw.githubusercontent.com/devicons/devicon/master/icons/postgresql/postgresql-original-wordmark.svg"
              alt="postgresql"
              width="40"
              height="40"
            />
            <img
              src="https://www.vectorlogo.zone/logos/getpostman/getpostman-icon.svg"
              alt="postman"
              width="40"
              height="40"
            />
            <img
              src="https://raw.githubusercontent.com/devicons/devicon/master/icons/python/python-original.svg"
              alt="python"
              width="40"
              height="40"
            />
            <img
              src="https://raw.githubusercontent.com/devicons/devicon/master/icons/react/react-original-wordmark.svg"
              alt="react"
              width="40"
              height="40"
            />
            <img
              src="https://reactnative.dev/img/header_logo.svg"
              alt="reactnative"
              width="40"
              height="40"
            />
            <img
              src="https://raw.githubusercontent.com/devicons/devicon/master/icons/swift/swift-original.svg"
              alt="swift"
              width="40"
              height="40"
            />
            <img
              src="https://raw.githubusercontent.com/devicons/devicon/master/icons/typescript/typescript-original.svg"
              alt="typescript"
              width="40"
              height="40"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;
