import Home from "../pages/home/Home";
import About from "../pages/about/About";
import Work from "../pages/work/Work";

export interface RouteType {
  path: string;
  component: () => JSX.Element;
  name: string;
}

const routes: RouteType[] = [
  {
    path: "/",
    component: Home,
    name: "Home",
  },
  {
    path: "/about",
    component: About,
    name: "About",
  },
  // {
  //   path: "/work",
  //   component: Work,
  //   name: "Work",
  // },
];

export default routes;