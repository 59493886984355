import { motion } from "framer-motion";

interface TextAnimationProps {
    inputText: string;
  }
  
  const TextAnimation = ({ inputText }: TextAnimationProps) => {
    return (
      <motion.div className="text">
        {inputText.split("").map((letter, index) => (
          <motion.span
            key={index}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.2, delay: index * 0.1 }}
          >
            {letter}
          </motion.span>
        ))}
      </motion.div>
    );
  }
export default TextAnimation;