import React from "react";
import { Link } from "react-router-dom";
import { RouteType } from "../config/routes";

interface NavigationTabsProps {
  routes: RouteType[];
}

const NavigationTabs: React.FC<NavigationTabsProps> = ({ routes }) => {
  return (
    <div className="tabs">
      {routes.map((route) => (
        <Link
          key={route.path}
          to={route.path}
          className={`tab${
            location.pathname === route.path ? " selected" : ""
          }`}
        >
          {route.name}
        </Link>
      ))}
    </div>
  );
};

export default NavigationTabs;
