import "./About.css";
import NavigationTabs from "../../components/Navigationtabs";
import routes from "../../config/routes";

const About = () => {
  return (
    <div className="home-container">
      {" "}
      <div className="right-section">
        <NavigationTabs routes={routes} />{" "}
      </div>
      {/* Pass the routes array to the component */}
      {/* The rest of your About component */}
      <iframe
        title="My CV"
        src="/assets/cv-portfolio.pdf" // Replace with the actual path to your PDF file
        width="100%"
        height="500px" // You can adjust the height as needed
      ></iframe>
    </div>
  );
};

export default About;
